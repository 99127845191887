/* CSS for the overlay */
.campusScreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.7
  ); /* Adjust the alpha value for transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Adjust the z-index as needed to ensure it's above other content */
}

/* CSS for the dialog container */
.campusScreenDialogContainer {
  /* Style your dialog container as needed */
  background-color: white;
  /* padding: 20px; */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow: auto; /* Enable scrolling if the content overflows */
  height: 100%;
  width: 100%;
}

/* Adjust the styles for the dialog content as needed */
.campusScreenDialog h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.campusScreenDialog p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.campusScreenDialog button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.campusScreenDialog button:hover {
  background-color: #0056b3;
}

.campusScreenDialogHero {
  height: auto;
  width: 100%;
  position: relative;
}
.campus-dialog-hero-image {
  width: 100%;
  height: 1024px;
}
.campusScreenContent {
  margin-top: 72px;
  margin-left: 72px;
  margin-right: 72px;
  height: auto;
}
.campusScreen_Fondo img {
  width: 100%;
}
.campusScreenColum {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 15px;
}
.campusScreenColumUno {
  padding-right: 10px;
}

.campusScreenColumUno img {
  width: 100%;
  height: 100%;
}

.campusScreenColumDos {
  padding-left: 10px;
}
.campusScreenColumDos img {
  width: 100%;
  height: 100%;
}
.campusScreen_Completa {
  width: 100%;
  margin-top: 20px;
}
.campusScreen_Completa img {
  width: 100%;
  height: 100%;
}
.campusScreenColumDos h2 {
  text-align: left;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  color: #000000;
}
.campusScreenColumDos p {
  font-family: "Poppins";
  font-weight: 400;
  text-align: left;
  font-size: 20px;
  line-height: 30px;
}

.dialogClose {
  position: absolute;
  /* display: block; */
  /* justify-content: end; */
  /* left: 1348px; */
  top: 25px;
  right: 12px;
  margin-right: 30px;
  z-index: 1000;
}

.video-title-UII {
  font-family: "Poppins";
  font-weight: 600;
  text-align: right;
  font-size: 29px;
  line-height: 43.5px;
  letter-spacing: 0.28rem;
  color: white;
  right: 0px;
  position: absolute;
  bottom: 5%;
  margin-right: 72px;
}

.campus-screen-last {
  margin-top: 60px;
}

.campusScreenColumDos h2 {
  margin-top: 0;
  font-family: "Poppins";
}
.campusScreenColumUnoUbi {
  padding-right: 10px;
}
@media screen and (max-width: 420px) {
  .campusScreenContent {
    margin-top: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .campusScreen_Fondo {
    position: relative;
    overflow: hidden;
  }
  .campusScreen_Fondo img {
    width: 1067px;
    height: 667px;
    position: relative;
    left: -309px;
  }
  .campusScreenColum {
    grid-template-columns: 100%;
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .campusScreenColumUno {
    padding-right: 0;
  }
  .campusScreenColumDos {
    margin-top: 16px;
    padding-left: 0;
  }
  .campusScreen_Completa {
    position: relative;

    overflow: hidden;
  }
  .campusScreen_Completa img {
    width: 1053px;
    height: 670px;
    position: relative;
    left: -339px;
  }
  .campusScreen_Completa .completaUltra {
    left: -78px;
  }
  .video-title-UII {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.28rem;
    color: black;
    /* right: 0px; */
    position: relative;
    /* bottom: 5%; */
    text-transform: uppercase;
    text-align: center;
    margin-right: 0;
  }
  .address {
    margin-left: 0;
  }
}
