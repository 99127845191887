:root {
  --ancho_img: 100%;
  --alto_img: 100vh;
  --ancho_border: 299px;
}

.ultra_animated {
  outline: 0px solid white;
  height: calc(var(--alto_img) - calc(var(--ancho_border) * 2));
  width: calc(var(--ancho_img) - calc(var(--ancho_border) * 2));
  left: var(--ancho_border);
  top: var(--ancho_border);
  position: absolute;
}

.ultra_fondo {
  height: 100vh;
  width: 100%;
  background-image: url("../../../images/Menu/ultrapark/AzoteaUlt.jpg");
  background: url("../../../images/Menu/ultrapark/AzoteaUlt.jpg") no-repeat left center;
  background-size: cover;
  max-height: 100%;
  max-width: 100%;
}

.valores-grid-container-mobile {
  display: none;
}

.ultra_container {
  position: relative;
  height: 100%;
}

.history {
  width: 100%;
  /* height: 100vh; */
  background-color: var(--color-bg);
}

.history .historyContainer {
  padding: 5% 15%;
}

.historyContent p {
  justify-content: center;
  position: relative;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.historyContainer h3 {
  color: black;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-transform: uppercase;
  letter-spacing: 0.28em;
}

.mission {
  width: 100%;
  height: 100%;
  position: relative;
}

.mission .missionContainer {
  /* background: url("../../../images/Menu/ultrapark/GradientMission.png");
  background-repeat: no-repeat;
  background-size: cover; */
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  min-height: 600px;
}

.mission .missionContainer .missionContent p {
  justify-content: center;
  align-items: center;
  text-align: justify;
  position: relative;
}

.contentMission {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 13rem);
  position: relative;
  justify-content: flex-start;
  align-items: center;
}

.contentMission__title {
  width: 100%;
  text-align: center;
  font-size: 9vw;
  color: var(--color-title);
  line-height: 1;
}

.missionContainer .missionColumn {
  background: #f3f9ff;
  text-align: left;
  padding: 10% 15%;
}

.missionContainer .missionColumn h3 {
  color: black;
  text-align: left;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 29px;
  line-height: 36px;
  text-transform: uppercase;
  letter-spacing: 0.28em;
}

#visionDiv {
  grid-row-start: 2;
  grid-column-start: 2;
}

.missionContainer .missionColumn-content {
  color: black;
  /* margin-top: 10px; */

  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  max-width: 80%;

  /* width: 321px; */
  /* align-items: flex-start; */
  /* border: 1px solid black; */
  /* flex: 1; */
}

/* Create an inner container with the same width as the screen */
.valores-grid-inner-container {
  width: 100vw;
  /* 100% of the viewport width */
  max-width: 100%;
  /* Ensure it doesn't exceed the screen width */
}

.valores-grid-items-row {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  row-gap: 0px;
  height: 120px;
  width: 120vw;
  text-align: center;
  margin: 0% 0% 0% -10%;
  align-content: center;
}

.valores {
  height: auto;
  position: relative;
  margin-bottom: 46px;
}

.valores .valores-container {
  display: grid;
  grid-template-columns: 35% 65%;
  height: auto;
}

.valores .valores-container {
  /* margin: 4rem auto; */
  padding: 4rem 0rem;
  display: grid;
  grid-template-columns: 35% 65%;
  height: 30%;
  margin-top: 5vh;
}

.valores-container .valores-column {
  margin: 50px 50px 20px 50px;
}

.valores-container .valores-column p {
  align-content: left;
}

.valores-description {
  color: black;
  align-content: left;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  max-width: 80%;
  margin-left: 24px;
}

.valores-grid-container {
  width: 100vw;
  max-width: 100%;
  overflow-x: auto;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 10vh;
}

/* Hide the scrollbar */
.valores-grid-container::-webkit-scrollbar {
  display: none;
}

.valores-grid-container p {
  margin: 25px 0px;
}

.valores-grid-container .valores-grid-items-row p {
  color: #c6d2dd;
  font-size: 80px;
  font-weight: 600;
  line-height: 120px;
  letter-spacing: 0.28em;
  text-align: left;
  padding: 0px 20px;
}

.valores-grid-container .valores-grid-items-row {
  text-align: center;
}

.valores-medium-item {
  font-size: 80px !important;
  font-weight: 500 !important;
  line-height: 120px !important;
  letter-spacing: 0em !important;
}

.valores-medium-spacing-item {
  font-size: 60px !important;
  font-weight: 600 !important;
  line-height: 90px !important;
  letter-spacing: 0.2em !important;
}

.valores-small-item {
  font-size: 60px !important;
  line-height: 90px !important;
  letter-spacing: 0em !important;
}

.valores-negrita-mobile {
  font-weight: 600;
  letter-spacing: 0.28em;
}

.small-letter p {
  letter-spacing: 0.28em;
  font-weight: 600;
  font-size: 50px;
  font-weight: 600;
  line-height: 90px;
  letter-spacing: 0.28em;
}

.small-letter p:nth-child(2) {
  font-weight: 600;
  letter-spacing: 0em;
}

.misionGradient {
  background: url("../../../images/Menu/ultrapark/gradient_mision.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.visionGradient {
  background: url("../../../images/Menu/ultrapark/gradient_vision.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.ultrapark-preFooter-description {
  text-align: left;
  position: relative;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  grid-column-start: 2;
  /* letter-spacing: 0em; */
  max-width: 80%;
}

.ultrapark-preFooter {
  margin: 4rem auto;
  /* padding: 4rem 0rem; */
  display: grid;
  grid-template-columns: 35% 65%;
  /* min-height: 600px; */
  /* height: inherit; */
}

@media screen and (max-width: 420px) {
  .ultra_fondo {
    height: 392px;
    width: 100%;
    background-size: cover;
    background-position: center;
  }

  .historyContent p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }

  .mission .missionContainer {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    min-height: 600px;
  }

  .misionGradient {
    background: url("../../../images/Menu/ultrapark/gradient_mision.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    grid-row-start: 2;
    height: 496px;
  }

  .missionContainer .missionColumn {
    background: #f3f9ff;
  }

  .visionGradient {
    background: url("../../../images/Menu/ultrapark/gradient_mision.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    grid-row-start: 4;
    height: 519px;
    background-position: 100% 50%;
  }

  .missionContainer .missionColumn-content {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }

  /* heigth in px because lossing the heigth otherwise */
  #visionDiv {
    grid-row-start: 3;
    grid-column-start: 1;
  }

  .valores-description {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    max-width: 100%;
    text-align: left;
    margin-right: 16px;
    margin-left: 16px;
    margin-bottom: 24px;
  }

  .valores .valores-container {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    /* height: 100%; */
  }

  .valores-grid-container-mobile {
    margin-left: -0vw;
  }

  .ultrapark-preFooter-description {
    text-align: left;
    font-family: Poppins;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    grid-column-start: 2;
    max-width: 100%;
    /* padding: 0 10%; */
    /* letter-spacing: 0em; */
  }

  .ultrapark-preFooter {
    margin: 24px 16px;
    /* padding: 4rem 0rem; */
    display: flex;
    flex-direction: column;
    /* min-height: 600px; */
    /* height: inherit; */
  }

  .valores-container .valores-column {
    /* padding: 3rem 10%; */
    margin: 24px 16px;
  }

  .valores-container .valores-column p {
    text-align: left;
  }

  .valores-grid-container {
    display: block;
  }

  .valores-grid-container {
    display: none;
  }

  .valores-letra-mobile {
    color: #c6d2dd;
    margin: 12px;
    font-family: Poppins;
    font-size: 6vw;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
  }

  .valores-negrita-mobile {
    font-weight: 600;
    letter-spacing: 0.28em;
    color: #c6d2dd;
    font-size: 7vw;
  }

  .valores-grid-container-mobile {
    display: block;
    width: 150vw;
  }

  .valores-grid-items-row {
    display: grid;
    height: 45px;
    width: 100%;
    text-align: left;
    margin-left: 0px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .valores-container {
    margin-left: 10%;
  }

  .valores {
    height: auto;
  }

  .valores-grid-container-mobile-wrapper {
    max-width: 110vw;
    overflow: hidden;
    display: grid;
    justify-content: center;
  }

  .missionContainer .missionColumn {
    background: #f3f9ff;
    text-align: left;
    padding: 20% 10%;
  }
}