.footer {
  background-color: #ffffff;
  color: #000000;
  padding: 3rem 2rem;
  column-count: 1;
}

.footer .footercontainer {
  height: 100%;
  width: 100%;
  display: inline-grid;
  grid-template-columns: 35% 65%;
  grid-template-rows: 65% 35%;
  gap: 10px;
}

.footer-menu {
  margin: 20px;
  text-align: justify;
  width: 100%;
  max-width: 100%;
  grid-row-start: 1;
  grid-row-end: 3;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.28em;
  text-align: left;
}

.footer-menu p {
  margin-left: 40px;
}

.footer-menu li a {
  color: #171616;
  text-decoration: none;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}

.footer-menu li a:hover {
  padding-bottom: 4px;
}

.footer-menu li:nth-child(1) a:hover {
  border-image: linear-gradient(45deg, #50ffd0, #0079d6);
  border-bottom: 5px solid transparent;
  border-image-slice: 1;
}

.footer-menu li:nth-child(2) a:hover {
  border-image: linear-gradient(60deg, #6720bb, #eaadc2);
  border-bottom: 5px solid transparent;
  border-image-slice: 1;
}

.footer-menu li:nth-child(3) a:hover {
  border-image: linear-gradient(45deg, #50ffd0, #a838ff);
  border-bottom: 5px solid transparent;
  border-image-slice: 1;
}

.footer-menu li:nth-child(4) a:hover {
  border-image: linear-gradient(45deg, #ffcc9d, #ff5e4f);
  border-bottom: 5px solid transparent;
  border-image-slice: 1;
}

.footer-menu li:nth-child(5) a:hover {
  border-image: linear-gradient(45deg, #f2eb3f, #62ff89);
  border-bottom: 5px solid transparent;
  border-image-slice: 1;
}

.socialMedia {
  margin: 20px 40px;
  text-align: center;
  grid-row-start: 2;
  grid-column-start: 2;
  position: relative;
}

.socialMedia-wrapper {
  position: absolute;
  right: 60px;
  display: inline-grid;
  grid-template-columns: auto 1fr 1fr 1fr;
  gap: 5px;
}

.bolsa-empleo-nav-link {
  margin-right: 15px;
}

.bolsa-empleo-nav-link:link {
  color: #000000;
}

.bolsa-empleo-nav-link:visited {
  color: #000000;
}

.bolsa-empleo-nav-link:hover {
  color: #000000;
}

.bolsa-empleo-nav-link:active {
  color: #000000;
}

.getInTouch {
  text-align: justify;
  margin: 20px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.28em;
  text-align: left;
}

.getInTouch-wrapper {
  display: inline-grid;
  grid-template-columns: 30% 30% 30%;
  width: 100%;
  gap: 10px;
}

.getInTouch-content .contentInfo {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.getInTouch-content .title {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  height: 25px;
}

/* background-attachment: fixed;  this property made the background fix and cause a motion effect */
.footer {
  background: url("../../images/footer/Ultrapark-footerGradient.jpg");
  background-position: left bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.socialMedia-wrapper icon {
  height: 200px;
}

.socialMedia-wrapper a {
  font-family: "Poppins" !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  text-decoration: underline !important;
}

.butonesTranslate {
  text-align: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  justify-self: center;
  align-self: center;
  text-align: center;
  margin-bottom: 24px;
}

.botonesFooter {
  position: initial;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: right;
  margin-bottom: 20px;
}
.botonesFooter p {
  grid-column-end: 4;
  grid-column-start: 1;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.28rem;
  margin-bottom: 10px;
}
.butonesTranslate {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
  margin-bottom: 0;
}

.butonesTranslate button {
  width: 70px;
  height: 50px;
  border: 1px solid black;
  border-radius: 30px;
  font-size: 20px;
  font-weight: bold;
}

.butonesTranslate button[value="en"] {
  background-color: black;
  color: white;
}

.butonesTranslate button[value="es"] {
  background-color: white;
  color: black;
}

@media screen and (max-width: 420px) {
  .socialMedia-wrapper a {
    grid-column-end: 4;
    grid-column-start: 1;
  }

  .getInTouch-wrapper {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    padding: 0;
  }

  .footer .footercontainer {
    display: flex;
    flex-direction: column;
  }

  .getInTouch-content .contentInfo {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0;
  }

  .getInTouch-content .title {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
  }

  .footer {
    width: 100%;
    padding: 0;
  }

  .socialMedia-wrapper {
    position: initial;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: right;
  }

  .getInTouch {
    margin: 46px;
  }

  /* parte de los botones */
  .botonesFooter {
    position: initial;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: right;
  }
  .botonesFooter p {
    grid-column-end: 4;
    grid-column-start: 1;
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;

    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.28rem;
    margin-bottom: 10px;
  }
  .butonesTranslate {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0px;
    margin-bottom: 0;
  }

  .butonesTranslate button {
    width: 70px;
    height: 50px;
    border: 1px solid black;
    border-radius: 30px;
    font-size: 20px;
    font-weight: bold;
  }

  .botonesFooter {
    margin-right: 0px;
  }
}
