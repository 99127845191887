.oneStop_animated {
  outline: 0px solid white;
  height: calc(var(--alto_img) - calc(var(--ancho_border) * 2));
  width: calc(var(--ancho_img) - calc(var(--ancho_border) * 2));
  left: var(--ancho_border);
  top: var(--ancho_border);
  position: absolute;
}

.oneStop_container {
  height: auto;
  width: 100%;
}

/* Estilos para el fondo (imagen u otro contenido) */
.oneStop_fondo {
  /* Estilos para el fondo, ajusta según tus necesidades */
  background-image: url("../../../images/Menu/OneStopShop/OneStopShop_Hero.jpg") !important;
  background-size: cover;
  background-position: center;
  height: 100vh;
  /* Ajusta la altura según tus necesidades */

  /* Haz que la imagen sea responsive */
  max-width: 100%;
}

h1 {
  color: black;
}

/* parte de office solution */
.officeSolition {
  width: 100%;
  height: auto;
}

.officeSolution_Container {
  display: grid;
  grid-template-columns: 38% 62%;
  height: auto;
  margin-left: 113px;
  margin-right: 113px;
  border-bottom: 1.5px solid;
  padding-bottom: 65px;
  margin-bottom: 65px;
}

.officeSolition h1 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 29px;
  line-height: 43.5px;
  letter-spacing: 0.28rem;
  /*tiene que ser de 28% pero no me deja ponerlo */
  margin-top: 80px;
}

.officeSolition .h1Titulo {
  margin-top: 80px;
}

.oneH1 h1 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 29px;
  line-height: 43.5px;
  letter-spacing: 28%;
  color: #b352e4;
  margin-top: 20px;
  text-align: left;
}

.h1Plus {
  padding-right: 175px;
  text-align: right;
  max-width: 57%;
}

.officeText p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
}

/* part4e de plug and play */
.plus_Container {
  height: auto;
  margin-left: 113px;
  margin-right: 113px;
  /* border-bottom: 3px solid; */
}

.plusPlay_Container {
  display: grid;
  grid-template-columns: 34% 66%;
  height: auto;
}

.plugPlay_Container {
  display: grid;
  grid-template-columns: 38% 62%;
  height: auto;
  margin-left: 113px;
  margin-right: 113px;
  border-bottom: 1.5px solid;
  padding-bottom: 65px;
  margin-bottom: 65px;
}

.playCol {
  display: grid;
  grid-template-columns: 50% 50%;
  height: auto;
}

.plusCol {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  height: auto;
}

.plusParrafo p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
}

.plusText p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  max-width: 350px;
}

.plusText h2 {
  /* padding-left: 10px; */
  text-align: justify;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.28rem;
  color: #000000;
  text-transform: uppercase;
}

.plusImagen {
  width: 350px;
  height: 250px;
}

.plusImagen img {
  width: 350px;
  height: 250px;
  float: left;
  margin-bottom: 16px;
}

.colUno {
  margin-right: 10px;
  display: grid;
  grid-template-rows: auto 1fr;
  flex: 1 1 0;
}

.colDos {
  margin-left: 40px;
  display: grid;
  grid-template-rows: auto 1fr;
  flex: 1 1 0;
}

.colTres {
  margin-left: 10px;
  display: grid;
  justify-items: end;
  grid-template-rows: auto 1fr;
  flex: 1 1 0;
}

/* office as a service */

.officeSolitionColor {
  width: 100%;
  height: auto;
  background-color: #f3f9ff;
  padding-bottom: 50px;
}

.officeSolitionColor h1 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 29px;
  line-height: 43.5px;
  letter-spacing: 0.28rem;
  /*tiene que ser de 28% pero no me deja ponerlo */
  padding-top: 40px;
}

.officeService_Container {
  display: grid;
  grid-template-columns: 67% 33%;
  height: auto;
  margin-left: 113px;
  margin-right: 113px;
}

.OfficeAs {
  margin-right: 100px;
}

.ulOfficeAs ul {
  list-style: disc;
  text-align: start;
}

.officeAs_Container {
  display: grid;
  grid-template-columns: 33% 67%;
  height: auto;
  margin-left: 113px;
  margin-right: 113px;
}

.parrafoAs {
  text-align: left;
}

.parrafoAs p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.primerParra {
  margin: 0;
}

.flexSpace h1 {
  text-align: justify;
  font-size: 29px;
  font-family: "Poppins";
  line-height: 43.5px;
  font-weight: 600;
  letter-spacing: 10px;
  color: #7c9ce8;
  margin-bottom: 28px;
}

/* ultima section de servicios adicionales */
.serviceAdicio_Container {
  display: grid;
  grid-template-columns: 35% 65%;
  height: auto;
  margin-left: 113px;
  margin-right: 113px;
  border-bottom: 3px solid #828282;
  padding-bottom: 40px;
  margin-bottom: 50px;
}

.serviceAdicioSin_Container {
  display: grid;
  grid-template-columns: 35% 65%;
  height: auto;
  margin-left: 113px;
  margin-right: 113px;
  padding-bottom: 40px;
  margin-bottom: 50px;
}

.officeAs_image h2 {
  text-align: left;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.28rem;
  /*supuestamente es de 28%, pero no se puede hacer */
  color: #934fbd;
  text-transform: uppercase;
}

.ulOfficeAs ul li {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  /* line-height: 30px; */
  color: #000000;
}

.adicionalColum {
  margin-right: 200px;
}

@media screen and (max-width: 420px) {
  .h1Plus {
    max-width: 80%;
    padding-right: 0;
    text-align: left;
  }

  .officeSolition h1 {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.28rem;
    font-weight: 600;
    font-family: "Poppins";
    /* padding-left: 26px; */
  }

  .officeSolitionColor h1 {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.28rem;
    font-weight: 600;
    font-family: "Poppins";
    /* padding-left: 26px; */
  }

  .oneH1 h1 {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.28rem;
    font-weight: 600;
    font-family: "Poppins";
    padding-left: 0;
  }

  .oneStop_container p {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .ulOfficeAs ul li {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .plusImagen {
    width: 100%;
    margin-bottom: 16px;
  }

  .plusImagen img {
    width: 100%;
  }

  .officeSolution_Container {
    grid-template-columns: 100%;
    margin-left: 16px;
    margin-right: 16px;
  }

  .plugPlay_Container {
    grid-template-columns: 100%;
    margin-left: 16px;
    margin-right: 16px;
  }

  .playCol {
    grid-template-columns: 100%;
  }

  .plus_Container {
    margin-left: 16px;
    margin-right: 16px;
  }

  .plusPlay_Container {
    grid-template-columns: 100%;
  }

  .plusCol {
    grid-template-columns: 100%;
  }

  .officeService_Container {
    grid-template-columns: 100%;
    margin-left: 16px;
    margin-right: 16px;
  }

  .OfficeAs {
    margin-right: 0;
  }

  .officeAs_Container {
    grid-template-columns: 100%;
    margin-left: 16px;
    margin-right: 16px;
  }

  .colUno {
    margin-right: 0;
  }

  .colDos {
    margin-left: 0;
    margin-right: 0;
  }

  .colTres {
    margin-left: 0;
  }

  .flexSpace h1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.28rem;
    font-family: "Poppins";
  }

  .serviceAdicio_Container {
    grid-template-columns: 100%;
    margin-left: 16px;
    margin-right: 16px;
  }

  .adicionalColum {
    margin-right: 0;
  }

  .serviceAdicioSin_Container {
    grid-template-columns: 100%;
    margin-left: 16px;
    margin-right: 16px;
  }
}
