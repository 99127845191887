.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 62px;
  line-height: 93px;
  color: #ffffff;
}

h2 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 50px;
  line-height: 75px;
  color: #ffffff;
}

h3 {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
}

.sh1 {
  /* SH1 - Sub Header 1 */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 29px;
  line-height: 44px;
  /* identical to box height */

  letter-spacing: 0.28em;
  text-transform: uppercase;
}

.sh2 {
  /* SH2 - Sub Header 2 */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.28em;
  text-transform: uppercase;
}

body {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}

.cta {
  /* CTA - Text */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
}

.buttonPrimario {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 32px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #ffffff;
  border-radius: 26px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 420px) {
  h1 {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    color: #ffffff;
  }
}
