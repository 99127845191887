.whyContainer {
  height: 100 vh;
  min-height: 600px;
  padding: 7%;
  flex-direction: column;
  text-align: left;
  vertical-align: top;
  background-color: #f3f9ff;
  grid-gap: 30px;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 50% 50%;
}

.whyContainer .whyColumn h3 {
  color: black;
  align-content: left;
}
.whyContainer .column-content {
  margin-right: 20px;
  padding: 5px;
  color: black;
  align-content: left;
  margin-top: 10px;

  font-family: Poppins;
  font-size: 0.7em;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;

  /* width: 321px; */
  /* align-items: flex-start; */
  /* border: 1px solid black; */
  /* flex: 1; */
}

@media screen and (max-width: 420px) {
  .whyContainer {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    /* padding: 15% 7%; */
    grid-gap: 0px;
  }

  .whyColumn {
    margin: 0%;
  }
  .card {
    max-width: 100%;
    margin: 1rem;
  }

  .columIzq {
    text-align: left;
    max-width: 60px;
    margin-top: 0;
  }

  .columDer {
    padding-bottom: 20px;
    text-align: right;
  }

  .column-content-Izqui {
    text-align: left;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
  }
  .column-content-Dere {
    text-align: right;
    font-family: "Poppins";
    font-weight: 400;
    size: 16px;
    line-height: 24px;
  }
  .columIzq h3 {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
  }
  .whyContainer h3 {
    font-family: "Poppins";
    font-weight: 500;
    size: 20px;
    line-height: 30px;
  }

  /* this is added to cener the title */
  /* margin-block-end: auto */
  .sh1 {
    margin-block-end: auto;
    text-align: center;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 28%;
    margin: 60px 0;
  }
}
