.sos-dialog-title {
  font-family: "Poppins";
  font-weight: 900;
  color: black;
  align-items: center;
  justify-content: center;
  justify-items: center;
  font-size: 8vw;
  letter-spacing: -0.05em;
  margin: 0;
  text-indent: -0.05em;
  /* position: absolute; */
  /* align-content: end; */
}
.sos-dialog-container {
  text-align: center;
}
.sos-dialog-container-title {
  background-position: 0px 0px;
  background: linear-gradient(#ffcc9d, #ff5e4f);
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  height: 100vh;
}

.menuFlechaDialog {
  position: absolute;
  bottom: 1em;
}

.sos-dialog-container .close-dialog {
  position: absolute;
  top: 25px;
  right: 12px;
  background: none;
  border: 0px;
  width: 30px;
  height: 30px;
  display: block;
  justify-content: center;
  margin: 0 30px 0 0;
  cursor: pointer;
  transition: opacity 0.3s;
  z-index: 10;
}
.sos-dialog-logo {
  position: absolute;
  top: 25px;
  background: none;
  border: 0px;
  display: block;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.3s;
  z-index: 10;
  left: 70px;
}
.sos-dialog-logo img {
  /* width: 250px;
  height: auto;
  margin: 30px; */
}
@media screen and (max-width: 420px) {
  .menuFlechaDialog img {
    height: 60%;
    width: 60%;
  }
}
