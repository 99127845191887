.header {
  position: absolute;
  height: 90px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  transition: 0.3s ease-in;
  overflow: hidden;
  --color-menu-act-bg: white; /* Color when the menu is open */
  --color-menu-bg: var(--primary-color);
  /* background-color: var(--color-menu-bg); */
}

.header-bg {
  /* background-color: var(--color-menu-act-bg); */
  /* z-index: 1003; */
  /* background-color: transparent; */
}

.animation h1 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 62px;
  line-height: 93px;
  color: black;
  padding: 0px;
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* max-width: 1240px; */
  margin: auto;
  height: 100%;
  padding: 0 0;
}

.header .nav-menu a {
  color: var(--primary-color);
}
/* 
.header .navbar img {
  width: 250px;
  height: auto;
  margin: 30px;
} */

/* Here was change to move the items to t he overlay dialog. */
.header .nav-menu {
  /* display: flex; */
  position: fixed;
  left: -100%;
  top: 90px;
  flex-direction: column;
  /* background-color: rgba(0, 0, 0, 0.9); */
  width: 100%;
  height: 90vh;
  z-index: 999;
  text-align: center;
  transition: 0.3s;
}

/* This was move here for the hamburger style  */
.nav-menu.active {
  left: 0;
}

.nav-item {
  margin: 1.5rem 0;
}

.header .nav-item {
  /* padding: 1rem; */
  font-weight: 500;
}

.nav-item .active {
  padding-bottom: 12px;
  border-bottom: 3px solid var(--secondary-color); /* This color is set in the index.css as parameter, attribute */
}

.header .nav-item a:hover {
  padding-bottom: 12px;
  border-bottom: 3px solid var(--secondary-color);
}

.hamburger {
  display: block;
  justify-content: end;
  width: 50px;
  height: 35px;
  /* left: 1348px; */
  top: 14px;
  margin: 0 30px 0 0;
}

.logo-ultra {
  position: sticky;
  top: 25px;
  left: 70px;
}

@media screen and (max-width: 940px) {
  .logo-ultra {
    position: sticky;
    top: 25px;
    left: 30px;
  }

  .header {
    max-width: 100%;
    /* background-color: rgba(0, 0, 0, 0.9); */
  }

  .header .navbar {
    max-width: 100%;
  }

  .hamburger {
    display: block;
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 90px;
    flex-direction: column;
    /* background-color: rgba(0, 0, 0, 0.9); */
    width: 100%;
    height: 90vh;
    z-index: 999;
    text-align: center;
    transition: 0.3s;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: 1.5rem 0;
  }

  .header .navbar img {
    /* width: 150px; */
  }

  @media (prefers-reduced-motion: no-preference) {
    .logo {
      /* animation: App-logo-spin infinite 20s linear; */
    }
  }
}
