.costaRica_animated {
  outline: 0px solid white;
  height: calc(var(--alto_img) - calc(var(--ancho_border) * 2));
  width: calc(var(--ancho_img) - calc(var(--ancho_border) * 2));
  left: var(--ancho_border);
  top: var(--ancho_border);
  position: absolute;
}

/* Estilos para el contenedor principal */
.costaRica_container {
  display: flex;
  flex-direction: column; /* Apila los elementos en una columna por defecto */

  /* Ajusta el espaciado entre los elementos */
  gap: 20px;
}

/* Estilos para el fondo (imagen u otro contenido) */
.costarica_fondo {
  /* Estilos para el fondo, ajusta según tus necesidades */
  background-image: url("../../../images/Menu/costarica/CostaRica_Hero.jpg") !important;
  background-size: cover;
  background-position: center;
  height: 100vh; /* Ajusta la altura según tus necesidades */

  /* Haz que la imagen sea responsive */
  max-width: 100%;
}

.grient_Historia {
  /* background-image: url("../../../images/Menu/costarica/Gradient_CostaRica_History.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom; */
}

/* Estilos para la sección .crHistory */
.crHistory {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 0;
  /* gap: 0px; */
  /* padding: 20px; */
  overflow: hidden;
}
.CostaricaText {
  margin-left: 56px;
  margin-right: 100px;
  margin-top: 100px;
}

/* Estilos para el contenedor de la imagen */

/* Estilos para la imagen */
.crImage-effect img {
  max-width: 100%;
  height: 100%;
  width: 100%;
}

/* Estilos para el contenedor del texto */
.crHistoryContainer {
  text-align: left;
  max-width: 100%;

  background-image: url("../../../images/Menu/costarica/Gradient_CostaRica_History.jpg");
  background-size: 550px;
  background-repeat: no-repeat;
}

.crHistoryContainer h4 {
  font-size: 29px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 43.5px;
  letter-spacing: 0.28rem;
}

/* Estilos para el contenido de texto */
.crHistoryContainer p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
}

/* Estilos adicionales para hacerlo más atractivo visualmente */
.crHistoryContainer p:last-child {
  margin-bottom: 0; /* Elimina el margen inferior del último párrafo */
}

/* estilo para AMBIENTE */
.crAmbiente {
  display: grid;
  grid-template-columns: 50% 50%;
  overflow: hidden;
  margin: 0;
}
.ambienteTexto {
  margin-right: 98px;
  margin-left: 98px;
}
.ambienteTexto h4 {
  font-family: "Poppins";
  font-size: 29px;
  font-weight: 600;
  line-height: 43.5px;
  letter-spacing: 0.28rem;
  text-align: left;
}
.ambienteTexto p {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}

/* Estilos para el contenedor del texto */
.crAmbienteContainer {
  max-width: 100%;
  align-self: center;
  height: 100%;
  background-image: url("../../../images/Menu/costarica/Gradient_CostaRica_Cliente.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  display: flex;
  align-items: center;
}

/* Estilos para el título */
.CrH4 {
  font-size: 24px;
  margin: 0;
}

/* Estilos para el contenido de texto */

/* Estilos adicionales para hacerlo más atractivo visualmente */
.crAmbienteContainer p:last-child {
  margin-bottom: 0; /* Elimina el margen inferior del último párrafo */
}

/* estilo para Talento */

.crTalento {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 0;
  /* gap: 20px;
  padding: 20px; */
  overflow: hidden;
}

/* Estilos para el contenedor del texto */
.crTalentoContainer {
  text-align: left;
  max-width: 100%;
  background-image: url("../../../images/Menu/costarica/Gradient_CostaRica_Talento.jpg");
  /* background-size: 51%; */
  background-repeat: no-repeat;
  /* background-position: left; */
}
.talentoText {
  margin-left: 56px;
  margin-top: 63px;
  margin-right: 74px;
  margin-bottom: 24px;
}
.talentoText h4 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 29px;
  line-height: 43.5px;
  letter-spacing: 0.28rem;
}
.talentoText p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}

/* Estilos para el título */
.CrH4 {
  font-size: 24px;
  margin: 0;
}

/* Estilos adicionales para hacerlo más atractivo visualmente */
.crTalentoContainer p:last-child {
  margin-bottom: 0; /* Elimina el margen inferior del último párrafo */
}

@media screen and (max-width: 420px) {
  .crHistory {
    grid-template-columns: 100%;
  }
  .CostaricaText {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 10px;
  }
  .crHistoryContainer h4 {
    padding-right: 60px;
    font-size: 24px;
    font-family: "Poppins";
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.28rem;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .crHistoryContainer p {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .crImage-effect {
    order: 2;
  }
  .crHistoryContainer {
    order: 1;
  }
  .crAmbiente {
    grid-template-columns: 100%;
  }
  .ambienteTexto {
    margin-right: 16px;
    margin-left: 16px;
    margin-bottom: 10px;
    margin-top: 100px;
  }
  .ambienteTexto h4 {
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.28rem;
    text-align: left;
  }
  .ambienteTexto p {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .crTalento {
    grid-template-columns: 100%;
  }
  .crTalentoContainer {
    /* margin-bottom: 30px; */
  }
  .talentoText {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 26px;
    margin-top: 100px;
  }
  .talentoText h4 {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.28rem;
  }
  .talentoText p {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .crTalentoContainer p:last-child {
    margin-bottom: 16px; /* Elimina el margen inferior del último párrafo */
  }
}
