/* Fade effect */
.js body {
  opacity: 0;
  transition: opacity 0.3s;
}

.gradient .js body.render {
  opacity: 1;
}

/* Page Loader */
.js .loading::before {
  content: "";
  position: fixed;
  z-index: 100000;
  top: 50;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-bg);
}

.js .loading::after {
  content: "";
  position: fixed;
  z-index: 100000;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  pointer-events: none;
  border-radius: 50%;
  opacity: 0.4;
  background: var(--color-link);
  animation: loaderAnim 0.7s linear infinite alternate forwards;
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
}
.gradient {
  margin: 0;
}

.gradient a {
  text-decoration: none;
  color: var(--color-link);
  outline: none;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 62px;
  line-height: 93px;
}

/* Icons */
.gradient .icon {
  display: block;
  margin: 0 auto;
  fill: currentColor;
}

.gradient .content {
  position: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-height: 100vh;
  --color-bg: white;

  --color-text: #ededed;
  --color-link: #fff;
  --color-link-hover: #5d5050;
  --color-info: #5d5050;

  /* --color-menu: #fff; color de los i tem del menu. */

  --color-menu-hover: black;
  --color-title: black; /* header letter menu */
  --color-subtitle: red; /* body the dialog menu */
  --color-deco: #161616;

  background-color: var(--color-bg);
}

.content--reveal {
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
}

/* :root {
  overflow: hidden;
} */

.gradient .contentDetail {
  height: 0px;
  max-height: 0px;
  min-height: 0vh;
  overflow: hidden;
  overflow-y: hidden;

  position: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  --color-bg: white;
  --color-text: #ededed;
  --color-link: #fff;
  --color-link-hover: #5d5050;
  --color-info: #5d5050;

  /* --color-menu: #fff; color de los i tem del menu. */

  --color-menu-hover: black;
  --color-title: black; /* header letter menu */
  --color-subtitle: red; /* body the dialog menu */
  --color-deco: #161616;
  background-color: var(--color-bg);
}

.gradient .content__inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50%;
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: end;

  opacity: 0;
  transition: opacity 0.3s;
  /* z-index: 10001; */
}

/* here seems to be  */
.js .content__inner {
  opacity: 0;
  transition: opacity 0.3s;
}

.gradient .content__title {
  font-size: 8vw;
  margin: 0;
  color: var(--color-title);

  letter-spacing: -0.05em;
  text-indent: -0.05em;
  font-family: "Poppins";
  font-weight: 900;
  /* text-transform: uppercase; */
}

.gradient .content__subtitle {
  max-width: 300px;
  text-align: center;
  font-size: 1.25em;
  font-weight: 400;
  margin: 0;
  color: var(--color-subtitle);
}

.gradient .content__close {
  position: absolute;
  top: 25px;
  right: 12px;
  background: none;
  border: 0px;
  width: 30px;
  height: 30px;
  display: block;
  justify-content: center;
  margin: 0 30px 0 0;
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.3s;
  z-index: 10;
}

.gradient .content__close:focus {
  outline: none;
}

.gradient .deco-title {
  font-size: 50vw;
  margin: 0;
  pointer-events: none;
  position: absolute;
  color: var(--color-deco);
}

/* Scene */
.gradient .scene {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.scene .blob-1 path {
  fill: url(#gradient-1);
}

.scene .blob-2 path {
  fill: url(#gradient-2);
}

.scene .blob-3 path {
  fill: url(#gradient-5);
}

.scene .blob-4 path {
  fill: url(#gradient-4);
}

.scene .blob-5 path {
  fill: url(#gradient-3);
}

.scene .blob-6 path {
  fill: url(#gradient-6);
}

.scene path:first-of-type {
  fill-opacity: 0.08;
}

.scene path:nth-of-type(2) {
  fill-opacity: 0.2;
}

.scene path:nth-of-type(3) {
  fill-opacity: 0.3;
}

.scene path:nth-of-type(4) {
  fill-opacity: 0.4;
}

.scene path:nth-of-type(5) {
  fill-opacity: 0.5;
}

.scene path:nth-of-type(6) {
  fill-opacity: 0.6;
}

.gradient .menu {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  margin: 0 0 0 25vw;
  font-size: 4vw;
  align-items: flex-start;
}

.gradient .menu__item {
  color: var(--color-menu);
  line-height: 105px;
  letter-spacing: -0.025em;
  text-indent: -0.025em;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 70px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  left: 0;
  bottom: 0;
}

.gradient .menu__item:hover,
.gradient .menu__item:focus {
  color: var(--color-menu-hover);
  /* transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
  transform: scale3d(1, 1, 1); */
}

/* seems to be not workign */
.gradient .menu__item::before {
  content: "";
  width: 60%;
  height: 6px;
  background: linear-gradient(45deg, #50ffd0, #0079d6);
  position: absolute;
  left: 0;
  bottom: 0;
  /* transform-origin: 0 0; */
  /* transform: scale3d(0, 1, 1); */
}

.gradient .menu__item:nth-child(2)::before {
  background: linear-gradient(60deg, #6720bb, #eaadc2);
  width: 60%;
  height: 6px;
}

.gradient .menu__item:nth-child(5)::before {
  background: linear-gradient(45deg, #f2eb3f, #62ff89);
  width: 60%;
  height: 6px;
}

.gradient .menu__item:nth-child(4)::before {
  background: linear-gradient(45deg, #ffcc9d, #ff5e4f);
  width: 60%;
  height: 6px;
}

.gradient .menu__item:nth-child(3)::before {
  background: linear-gradient(45deg, #50ffd0, #a838ff);
  width: 60%;
  height: 6px;
}

.gradient .menu__item--showDeco::before {
  transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
  transform: scale3d(1, 1, 1);
}

.gradient .menu__item--showDeco:hover::before {
  transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
  transform: scale3d(1.2, 1, 1);
}

.gradient .menu__item span {
  /* display: contents; */
  position: relative;

  font-family: "Poppins";
  font-style: normal;
  font-size: 62px;
  line-height: 93px;
  font-weight: 400;
}

:root {
  --ancho_img: 100%;
  --alto_img: 100vh;
  --ancho_border: 299px;
}
/* 
@media screen and (max-width: 55em) {
  .message {
    display: block;
  }
  .content {
    flex-direction: column;
    height: auto;
    min-height: 0;
    padding-bottom: 10em;
  }

  .info {
    margin: 0;
    text-align: center;
  }

  .codrops-links {
    margin: 0;
  }
  .gradient .menu {
    font-size: 2.75em;
    margin: 0 auto;
    align-items: center;
  }
  .content__inner {
    margin-top: 12em;
  }
  .content__close {
    top: 15em;
  }
} */

.menuFlecha {
  position: absolute;
  margin-bottom: 2rem;
  bottom: 0;
}

.menu__item .menu__item--showDeco::before {
  position: relative;

  font-family: "Poppins";
  font-style: normal;
  font-size: 62px;
  line-height: 93px;
  font-weight: 400;
}

@media screen and (max-width: 420px) {
  .menuFlecha {
    margin: 0 0 2rem 40%;
  }
  .menuFlecha img {
    height: 60%;
    width: 60%;
  }

  .gradient .menu {
    margin: 0 10%;
  }

  .gradient .menu__item span {
    /* display: contents; */
    position: relative;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
  }

  .gradient .content {
    position: inherit;
    display: flex;
    justify-content: left;
    align-items: center;
    /* padding-top: 2rem; */
  }

  .gradient .content__inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: end;

    opacity: 0;
    transition: opacity 0.3s;
    /* z-index: 10001; */
  }

  .gradient .menu__item {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    /* line-height: 30px; */
    letter-spacing: 0;
    text-wrap: nowrap;
    /* text-indent: -0.025em; */
    /* font-weight: 700; */
    /* font-size: 70px; */
  }

  .gradient .content__title {
    font-size: 40px;
    font-family: "Poppins";
    font-weight: 400;
    letter-spacing: 0.28rem;
  }
}
