.containerTree {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  background-color: #000000;
  text-align: center;
  align-content: center;
  justify-content: center;
}

.tree {
  width: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  /* position: relative; */
  text-align: center;
  align-content: center;
  justify-content: center;
}

.pathTree {
  /* transform: translate(35%, 25%); */
}

.splashTitle {
  letter-spacing: -0.05em;
  text-indent: -0.05em;
  font-family: "Poppins";
  font-weight: 900;
  color: white;
  font-size: 62px;
  text-transform: uppercase;
  text-align: center;
  justify-self: end;
}

.splashTitleContainer {
}

@media screen and (max-width: 420px) {
  .containerTree svg {
    height: 50%;
    width: 50%;
    align-self: center;
  }
  .splashTitle {
    font-size: 31px;
    position: absolute;
    text-align: center;
    justify-self: end;
    /* display: contents; */
    bottom: 25%;
    margin-left: 30%;
  }
}
