.campus_animated {
  outline: 0px solid white;
  height: calc(var(--alto_img) - calc(var(--ancho_border) * 2));
  width: calc(var(--ancho_img) - calc(var(--ancho_border) * 2));
  left: var(--ancho_border);
  top: var(--ancho_border);
  position: absolute;
}

/* Estilos para el contenedor principal */
.campus_container {
  flex-direction: column;
  /* Apila los elementos en una columna por defecto */

  /* Ajusta el espaciado entre los elementos */
  gap: 20px;
}

/* Estilos para el fondo (imagen u otro contenido) */
.campus_fondo {
  /* Estilos para el fondo, ajusta según tus necesidades */
  background-image: url("../../../images/Menu/Campus/Campus_Hero.jpg") !important;
  background-size: cover;
  background-position: center;
  height: 100vh;
  /* Ajusta la altura según tus necesidades */

  /* Haz que la imagen sea responsive */
  max-width: 100%;
}

/* Estilos CSS para el section de los campus */
.campus-section {
  text-align: center;
  padding: 40px;
  /* background-image: url("../../../images/Menu/Campus/bg_fluido.jpg"),
    url("../../../images/Menu/Campus/bg_coloide.jpg");
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat; */
}

.campus-title {
  font-size: 24px;
  margin-bottom: 64px;
  color: black;
}

.campus-container {
  grid-template-columns: 50% 50%;
  display: grid;
  justify-items: center;
}

.campus-item {
  margin: 10px;
  padding: 20px;
  display: block;
}

.campus-item img {
  height: 392px;
  width: 574px;
}

.campus-image {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
  width: 100%;
}

.campus-subtitle-container {
  display: flex;
}

.campus-subtitle {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 29px;
  line-height: 30px;
  letter-spacing: 0.28rem;

  text-align: left;
  margin-top: 10px;
  color: black;
}

.campus-subtitle-date {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: justify;
  margin-top: 10px;
  color: black;
  margin-left: 20px;
}

/* .campus_texto{
  align-items: center;
  justify-content: center;
  justify-self: center;
  align-content: center;
} */

.campusButton {
  width: auto;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  position: relative;
  text-transform: uppercase;
  border: 0;
  border-radius: 26px;
  gap: 32px;
  padding: 12px 32px 12px 32px;
}

/* tercera parte */

.containerAmenidades {
  display: flex;
  background-color: #f3f9ff;
}

.column1 {
  flex: 1;
  /* padding: 20px; */
  /* border: 1px solid #ccc; */
  display: flex;
  align-items: center;
}

.amenidades {
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins";
  font-weight: 500;
  letter-spacing: 0.28rem;
  margin-bottom: 24px;
  color: black;
}

.text h2 {
  color: black;
  font-size: 29px;
  line-height: 43.5px;
  font-family: "Poppins";
  font-weight: 600;
  letter-spacing: 0.28rem;
}

.text p {
  color: black;
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins";
  font-weight: 400;
}

.campusMedia {
  /* height: 100%;
  width: 100%; */
  margin: 20px 113px;
  text-align: center;
  /* grid-row-start: 2; */
  /* grid-column-start: 2; */
  /* position: relative; */
}

.campusMedia-wrapper {
  /* position:relative; */
  /* right: 60px;s */
  /* display: inline-grid; */
  /* grid-template-columns: 30% 30% 30%; */
  gap: 20px;
  bottom: 45px;
}

.campusMedia-wrapper icon {
  height: 200px;
}

.text {
  flex: 1;
  justify-content: left;
  text-align: left;
}

.campus-esca {
  background-image: url("../../../images/Menu/Campus/bg_coloide.jpg");
  background-size: cover;
}
.campus-upi {
  background-image: url("../../../images/Menu/Campus/bg_fluido.jpg");
  background-repeat: round;
  background-size: cover;
}

.column2 {
  flex: 1;
  /* padding: 20px; */
  /* border: 1px solid #ccc; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.column2 img {
  width: 100%;
  height: 100%;
}

.campusMedia img {
  height: 100%;
  width: 100%;
  max-width: 100%;
  /* Ajusta el tamaño de la imagen según tus necesidades */
}

@media screen and (max-width: 420px) {
  .campus-section {
    background-image: none;
    padding: 0;
  }

  .campus_fondo {
    height: 392px;
    width: 100%;
    background-size: cover;
    background-position: center;
  }

  .campus-container {
    grid-template-columns: 100%;
    display: grid;
    justify-items: center;
  }

  .campus-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.28rem;
    margin-top: 80px;
  }

  .campus-item {
    margin: 0px 16px 10px 16px;
    padding: 0;
  }

  .campus-subtitle-container {
    padding-right: 100px;
    flex-direction: column;
  }

  .campus-subtitle {
    font-size: 29px;
    line-height: 43.5px;
    letter-spacing: 0.28rem;
    margin-bottom: 0px;
  }

  .campus-subtitle-date {
    margin-left: 0;
    font-size: 24px;
    line-height: 36px;
    font-family: Poppins;
    letter-spacing: 0.28em;
    text-align: left;
    margin-bottom: 16px;
  }

  .campusButton {
    margin-bottom: 50px;
  }

  /* .amenidades */
  .containerAmenidades {
    /* margin-bottom: 20px; */
    display: flex;
    flex-direction: column;
    /* grid-template-columns: 100%; */
  }

  .campusMedia {
    height: 100%;
    margin: 0;
    margin-top: 50px;
    margin-left: 32px;
    margin-right: 32px;
    /* margin-bottom: 60px; */
    height: 519px;
  }

  .column1 {
    order: 1;
    height: auto;
  }

  .column2 {
    overflow: hidden;
    order: 2;
    /* margin-bottom: 10px; */
  }

  .column2 img {
    height: 519px;
    width: 721px;
  }

  .column2 .imgAll {
    margin-left: -301px;
  }

  .amenidades {
    margin-top: 40px;
  }

  .text h2 {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.28rem;
  }

  .text p {
    font-size: 16px;
    line-height: 24px;
  }

  .campus-item img {
    height: auto;
    width: auto;
  }
  .campus-esca {
    background-image: none;
    background-size: cover;
  }
  .campus-upi {
    background-image: none;
    background-repeat: round;
    background-size: cover;
  }
}
