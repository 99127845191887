.sosteni_animated {
  outline: 0px solid white;
  height: calc(var(--alto_img) - calc(var(--ancho_border) * 2));
  width: calc(var(--ancho_img) - calc(var(--ancho_border) * 2));
  left: var(--ancho_border);
  top: var(--ancho_border);
  position: absolute;
}

/* Estilos para el contenedor principal */
.sosteni_container {
  /* display: flex;
  flex-direction: column;  */

  /* Ajusta el espaciado entre los elementos */
  gap: 20px;
}

/* Estilos para el fondo (imagen u otro contenido) */
.sosteni_fondo {
  /* Estilos para el fondo, ajusta según tus necesidades */
  background-image: url("../../../images/Menu/sos/Soste_hero.jpg") !important;
  background-size: cover;
  /* background-position: center; */
  height: 100vh;
  /* Ajusta la altura según tus necesidades */

  /* Haz que la imagen sea responsive */
  max-width: 100%;
}

.soste_content {
  width: 100%;
  height: auto;
}

.ambiente_container {
  background-image: url("../../../images/Menu/sos/Soste_Fondo1.jpg");
  width: 468px;
  height: 1024px;
  background-repeat: no-repeat;
}

.ambiente_container {
  width: 100%;
  height: 1024px;
}

.ambienteCol {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 15% 85%;
  height: auto;
  padding: 150px 120px 120px 120px;
}

.imagenAmbiente {
  width: 528px;
  height: 524px;
  margin-top: 20px;
  grid-row-start: 2;
}

.imagenAmbiente img {
  width: 100%;
  height: 100%;
}

.iconAmbiente {
  display: flex;
  flex-direction: row;
  grid-column-start: 2;
  margin-top: 24px;
}

.iconAmbiente h1 {
  text-align: left;
  margin: 0;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 29px;
  line-height: 43.5px;
  letter-spacing: 10px;
  margin-left: 20px;
  text-transform: uppercase;
}

.parrafoAmbiente {
  text-align: left;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.responsabilidad_container {
  background-image: url("../../../images/Menu/sos/Soste_Fondo2.jpg");
  width: 468px;
  height: auto;
  background-repeat: no-repeat;
}

.responsabilidad_container {
  width: 100%;
  height: 1024px;
}

.innovacion_container {
  background-image: url("../../../images/Menu/sos/Soste_Fondo3.jpg");
  width: 468px;
  height: auto;
  background-repeat: no-repeat;
}

.innovacion_container {
  width: 100%;
  height: 1024px;
}

.textoAmbiente {
  grid-column-start: 2;
  display: contents;
}

@media screen and (max-width: 420px) {
  .sosteni_fondo {
    height: 389px;
    width: 100%;
    background-size: cover;
    background-position: center;
  }

  .ambiente_container {
    height: auto;
    background: none;
  }

  .ambienteCol {
    display: flex;
    flex-direction: column;
    grid-template-columns: 100%;
    height: auto;
    padding: 0;
    margin-left: 16px;
    margin-right: 16px;
  }

  .ambiente_container .iconAmbiente,
  .responsabilidad_container .iconAmbiente,
  .innovacion_container .iconAmbiente {
    order: -1;
    text-align: left;
    margin-top: 30px;
  }

  .iconAmbiente img {
    height: 38px;
    width: 53px;
  }

  .imagenAmbiente {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
  }

  .iconAmbiente {
    display: block;
  }

  .iconAmbiente h1 {
    text-align: left;
    margin-left: 0;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.28rem;
  }

  .responsabilidad_container {
    height: auto;
    background: none;
  }

  .innovacion_container {
    height: auto;
    background: none;
  }

  .parrafoAmbiente p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
}
